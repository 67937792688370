import React from 'react';
import styled from '@emotion/styled';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import Logo from '../images/logo.svg';
import circle from '../images/circle.svg';

type Props = {
    data: DatenschutzQuery;
};

const P = styled.p`
    ${tw`mb-6 font-sm leading-relaxed relative z-10`}
`;

const Title = styled.div`
    ${tw`mb-4 text-lg font-bold leading-tight z-10`}
`;

const Ul = styled.ul`
    ${tw`mt-2 list-disc z-10`}
`;

const Li = styled.li`
    ${tw`ml-4 mb-2`}
`;

export default ({ data }: Props) => {
    return (
        <Layout siteTitle={data.site?.siteMetadata?.title || ''}>
            <SEO title={data.site?.siteMetadata?.title || ''} keywords={[`AGB`, `DFP`, `Datenschutz`]} />
            <div className="bg-gradient-top pb-24 md:pb-34">
                <div className="container mx-auto pt-12 mb-24 md:mb-32 flex items-center justify-center md:justify-start z-10">
                    <Link to={`/`}>
                        <img src={Logo} alt="Logo" className="object-contain w-full h-full mb-0" />
                    </Link>
                </div>
                <div className="container mx-auto flex flex-col relative">
                    <motion.div
                        className="absolute circle-center z-0 pointer-events-none"
                        animate={{ y: [0, 20, 0] }}
                        transition={{ duration: 8, ease: 'easeInOut', loop: Infinity }}
                    >
                        <img src={circle} alt="circle" className="object-contain w-full h-full" />
                    </motion.div>
                    <h1 className="mb-8 text-3xl md:text-4xl font-bold leading-tight relative z-10">
                        Datenschutzerklärung
                    </h1>
                    <P>Juli 2020</P>
                    <Title>1. Über diese Datenschutzerklärung</Title>
                    <P>
                        Diese Datenschutzerklärung enthält Informationen darüber, wie Personendaten auf den Webseiten
                        von d.fp erhoben und bearbeitet werden.
                    </P>
                    <Title>2. Kontakt</Title>
                    <P>
                        Die Webseiten von d.fp sind ein Dienst der swisspeers AG. Sollten Sie Informationen über die
                        Erfassung und Bearbeitung von personenbezogenen Daten in diesem Zusammenhang wünschen, wenden
                        Sie sich bitte an <a href="mailto:info@swisspeers.ch">info@swisspeers.ch</a> oder swisspeers AG,
                        Zürcherstrasse 12, 8400 Winterthur.
                    </P>
                    <Title>3. Welche Daten wir zu welchen Zwecken bearbeiten</Title>
                    <div className="z-10">Wenn Sie über diese Webseite mit uns in Kontakt treten, erfassen wir</div>
                    <Ul>
                        <Li>
                            Nutzerdaten, d.h. die von Ihnen angegebenen Informationen (Name, E-Mail, Nachricht etc.)
                            sowie
                        </Li>
                        <Li>
                            Besucherdaten, d.h. die von Ihrem Gerät bereitgestellten oder anderweitig über Ihren Besuch
                            protokollierten Informationen (Verweildauer, IP-Adresse, verwendeter Browser und OS etc.).
                        </Li>
                    </Ul>
                    <div className="z-10">Wir verwenden diese Daten zu folgenden Zwecken:</div>
                    <Ul className="mb-6">
                        <Li>um Ihre Anfrage zu beantworten.</Li>
                        <Li>
                            um unsere Dienstleistungen zu verwalten, zu analysieren, weiterzuentwickeln und zu
                            verbessern sowie deren Effektivität zu messen und verstehen,
                        </Li>
                        <Li>um für den Schutz und die Sicherheit unserer Dienstleistung zu sorgen und</Li>
                        <Li>
                            um Nutzern Werbung per E-Mail, Brief oder Telefon zukommen zu lassen für Produkte und
                            Dienstleistungen von uns oder anderen Unternehmen, die nach unserer Auffassung für Sie von
                            Interesse sein können.
                        </Li>
                    </Ul>
                    <Title>4. Weitergabe an Dritte</Title>
                    <div className="z-10">Wir geben Besucher- und Nutzerdaten nur dann an Dritte bekannt, wenn</div>
                    <Ul>
                        <Li>die Zustimmung der betroffenen Person vorliegt,</Li>
                        <Li>
                            dies im Zusammenhang mit der Nutzung bzw. Erbringung unserer Dienstleistungen oder als
                            Reaktion auf eine Anfrage von Ihnen geschieht,
                        </Li>
                        <Li>
                            dies geschieht, um gesetzlichen oder behördlichen Verpflichtungen oder Aufforderungen
                            nachzukommen,
                        </Li>
                        <Li>
                            im Rahmen eines Verkaufs von swisspeers bzw. Anteilen oder Vermögenswerten von swisspeers
                            Personendaten an den potenziellen Käufer bekannt gegeben werden,
                        </Li>
                        <Li>dies zur Durchsetzung unserer Rechte und der Datenschutzerklärung erfolgt, oder</Li>
                        <Li>dies anderweitig gesetzlich zulässig ist.</Li>
                    </Ul>
                    <P>
                        Zur optimalen Abwicklung der Geschäftsprozesse ist es überdies notwendig, dass bestimmte
                        Besucher- und Nutzerdaten in unserem Auftrag durch Dritte bearbeitet werden. Diese Dritten
                        dürfen die Daten nur für unsere Zwecke und nicht für eigene Zwecke nutzen.
                    </P>
                    <Title>5. Bekanntgabe ins Ausland</Title>
                    <P>
                        Für gewisse Teile unserer Dienstleistungen haben wir externe Dienstleister beigezogen, deren
                        Server in der EU sowie den USA stehen. Wir geben Ihre Personendaten an solche Dienstleister
                        bekannt, soweit dies für unsere Dienstleistung nötig ist. Für die Bekanntgabe in die USA stützen
                        wir uns auf die Zertifizierung der Dienstleister gemäss Privacy Shield Abkommen zwischen der
                        Schweiz und den USA. Zudem können im Rahmen der genutzten Tracking Technologien Personendaten in
                        die USA bekannt gegeben werden. Genauere Informationen hierzu finden sie unter Ziff. 6 – Cookies
                        und Tracking-Technologien.
                    </P>
                    <Title>6. Cookies und Tracking-Technologien </Title>
                    <P>
                        Wir verwenden auf unserer Webseite Cookies. Das sind Textdateien, die auf Ihrem Gerät
                        gespeichert werden und Informationen über die Besucher und die Nutzung unserer Webseite
                        erfassen. Cookies ermöglichen unter anderem eine bessere Nutzung der Webseite. Wir verwenden
                        zudem Cookies, um statistische Auswertungen über die Nutzung unserer Webseite zu erstellen.
                        Hierzu verwenden wir Services von Drittanbietern (wie z.B. Google Analytics oder vergleichbare
                        Dienste), welche die Nutzung der Webseite messen und auswerten. Dazu werden aggregierte
                        Informationen verwendet, die keine Identifizierung des einzelnen Nutzers erlauben. Die
                        Funktionsdauer der entsprechenden Cookies ist für uns daher auf die Dauer Ihrer Sitzung
                        beschränkt. Der Drittanbieter (z.B. Google) kann jedoch Ihre Nutzung der Webseite verfolgen,
                        diese Daten mit Daten von anderen Webseiten kombinieren, die ebenfalls von diesem Drittanbieter
                        verfolgt werden, und diese Angaben für eigene Zwecke verwenden und allenfalls auch Ihnen
                        zuordnen. Für die Bearbeitung Ihrer Personendaten durch diesen Drittanbieter ist dieser
                        Drittanbieter verantwortlich und es gelten dessen Datenschutzbestimmungen. Zusätzliche
                        Informationen über die Datennutzung durch Google und Ihre Widerspruchsmöglichkeiten gegenüber
                        Google finden Sie unter folgendem Link:{' '}
                        <a href="https://www.google.de/policies/privacy/partners/">
                            https://www.google.de/policies/privacy/partners/
                        </a>
                    </P>
                    <P>
                        Wenn Sie nicht möchten, dass wir bei Ihrem Besuch Cookies verwenden, können Sie deren Nutzung in
                        den Einstellungen Ihres Browsers deaktivieren. In diesem Fall können bestimmte Teile unserer
                        Webseite nicht oder nicht vollumfänglich funktionsfähig sein.
                    </P>
                    <Title>7. Änderung dieser Datenschutzerklärung</Title>
                    <P>
                        Wir behalten uns vor, diese Datenschutzbestimmungen jederzeit anzupassen. Die neue Version tritt
                        mit Publikation auf der Webseite in Kraft.
                    </P>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query Datenschutz {
        site {
            siteMetadata {
                title
                description
                image
                siteUrl
            }
        }
    }
`;
